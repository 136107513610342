import { useCallback, useEffect, useRef, useState } from "react";

import "./App.css";

const first54Elements = {
  H: "Hydrogen",
  He: "Helium",
  Li: "Lithium",
  Be: "Beryllium",
  B: "Boron",
  C: "Carbon",
  N: "Nitrogen",
  O: "Oxygen",
  F: "Fluorine",
  Ne: "Neon",
  Na: "Sodium",
  Mg: "Magnesium",
  Al: "Aluminum",
  Si: "Silicon",
  P: "Phosphorus",
  S: "Sulfur",
  Cl: "Chlorine",
  Ar: "Argon",
  K: "Potassium",
  Ca: "Calcium",
  Sc: "Scandium",
  Ti: "Titanium",
  V: "Vanadium",
  Cr: "Chromium",
  Mn: "Manganese",
  Fe: "Iron",
  Co: "Cobalt",
  Ni: "Nickel",
  Cu: "Copper",
  Zn: "Zinc",
  Ga: "Gallium",
  Ge: "Germanium",
  As: "Arsenic",
  Se: "Selenium",
  Br: "Bromine",
  Kr: "Krypton",
  Rb: "Rubidium",
  Sr: "Strontium",
  Y: "Yttrium",
  Zr: "Zirconium",
  Nb: "Niobium",
  Mo: "Molybdenum",
  Tc: "Technetium",
  Ru: "Ruthenium",
  Rh: "Rhodium",
  Pd: "Palladium",
  Ag: "Silver",
  Cd: "Cadmium",
  In: "Indium",
  Sn: "Tin",
  Sb: "Antimony",
  Te: "Tellurium",
  I: "Iodine",
  Xe: "Xenon",
};

let currentElement = "H";
let atomicSymbol = true;
let blockSubmit = false;

const App = () => {
  const [elementText, setElementText] = useState(
    `What is the atomic name of the element with the atomic symbol of ${currentElement}?`
  );

  const inputRef = useRef(null);

  const getNextElement = () => {
    atomicSymbol = Math.random() < 0.5;
    const keys = Object.keys(first54Elements);
    const index = Math.floor(Math.random() * keys.length);
    currentElement = keys[index];

    setElementText(
      atomicSymbol
        ? `What is the atomic name of the element with the atomic symbol of ${currentElement}?`
        : `What is the atomic symbol of the element with the atomic name of ${first54Elements[currentElement]}?`
    );
  };

  useEffect(() => {
    getNextElement();
  }, []);

  const onSubmit = useCallback(
    (e) => {
      const input = inputRef.current;

      e.preventDefault();

      if (!input) {
        console.error("inputRef is null");
        return;
      }

      if (blockSubmit) {
        return;
      }

      blockSubmit = true;

      console.log(
        atomicSymbol,
        input.value.trim().toLowerCase(),
        currentElement,
        first54Elements[currentElement]
      );

      if (
        (atomicSymbol &&
          input.value.trim().toLowerCase() ===
            first54Elements[currentElement].toLowerCase()) ||
        (!atomicSymbol &&
          input.value.trim().toLowerCase() === currentElement.toLowerCase())
      ) {
        input.className = "correct";

        setElementText("Correct!");

        setTimeout(() => {
          input.value = "";
          input.className = "";

          blockSubmit = false;

          getNextElement();
        }, 1000);
      } else {
        input.className = "incorrect";

        console.log(atomicSymbol);

        setElementText(
          `Incorrect! The correct answer is ${
            atomicSymbol ? first54Elements[currentElement] : currentElement
          }`
        );

        setTimeout(() => {
          input.value = "";
          input.className = "";

          blockSubmit = false;

          getNextElement();
        }, 2000);
      }
    },
    [atomicSymbol, currentElement, first54Elements]
  );

  return (
    <div className="App">
      <h1>First 54 Elements Memorization Aid</h1>
      <h2>{elementText}</h2>
      <form onSubmit={onSubmit}>
        <input
          type="text"
          placeholder={
            atomicSymbol
              ? "Enter the atomic number here"
              : "Enter the atomic name here"
          }
          minLength={1}
          maxLength={atomicSymbol ? 8 : 2}
          required
          ref={inputRef}
        />
        <button type="submit">Check Answer</button>
      </form>
    </div>
  );
};

export default App;
